@import "Stylesheets/refosumu/base/v2/mixins";

.site-footer {
  position: relative;
  background-color: #fff;

  @include screen-md {
    padding: 0 2rem 2rem;
  }

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 8px;
    top: 0;
    left: 0;
    background-image: url('./images/blue_line.png');
    background-size: cover;
  }

  .container {
    max-width: calc(1100px + 2rem);
    margin: 0 auto;
    padding: 10px 0 0;
  }

  &__search-category-title-wrap,
  &__subsidy-category-title-wrap,
  &__useful-category-title-wrap,
  &__service-category-title-wrap {
    display: flex;

    @include screen-md {
      display: block;
      border-bottom: 0;
    }
  }

  &__about-links-navigation,
  &__search-links-navigation,
  &__reform-service-links-navigation,
  &__column-links-navigation,
  &__subsidy-links-navigation,
  &__useful-links-navigation {
    display: block;

    @include screen-md {
      padding-top: 20px;
    }
  }

  &__about-links-title,
  &__reform-service-links-title,
  &__column-links-title {
    padding: 0.7rem 0.5rem;
    background-color: #FFFFFF;
    color: #3c3c3c;
    font-size: 1.2rem;
    font-weight: bold;

    @include screen-md {
      margin-top: 2.5rem;
      margin-bottom: 2rem;
      padding: 0 0 0 1rem;
      background-color: transparent;
      color: #3c3c3c;
      font-weight: bold;
      background: url('./images/yellow_square_icon.svg') no-repeat 0 0;
      background-size: 10px 100%;
    }
  }

  &__about-links-title,
  &__reform-service-links-title,
  &__column-links-title {
    display: none;

    @include screen-md {
      display: block;
    }
  }

  &__column-category-title {
    width: calc(100% - 60px);
    padding: 1.3rem 1.2rem;
    font-size: 1.1rem;
    color: #3c3c3c;
    font-weight: bold;

    @include screen-md {
      width: auto;
      padding: 0;
      font-size: 1rem;
      color: #7B7B7B;
      font-weight: normal;
    }
  }

  &__search-links-title,
  &__subsidy-links-title,
  &__useful-links-title,
  &__service-links-title {
    width: calc(100% - 60px);
    padding: 1.3rem 1.2rem;
    font-size: 1.1rem;
    color: #3c3c3c;
    font-weight: bold;

    @include screen-md {
      width: auto;
      font-size: 1.2rem;
      margin-top: 2.5rem;
      margin-bottom: 2rem;
      padding: 0 0 0 1rem;
      background-color: transparent;
      color: #3c3c3c;
      background: url('./images/yellow_square_icon.svg') no-repeat 0 0;
      background-size: 10px 100%;
    }
  }

  &__subsidy-sub-category-open-trigger,
  &__search-sub-category-open-trigger,
  &__column-sub-category-open-trigger,
  &__useful-sub-category-open-trigger,
  &__service-sub-category-open-trigger {
    width: 60px;
    min-width: 60px;
    background-color: #fff;
    position: relative;
    cursor: pointer;

    &::after {
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      right: 30px;
      height: 25px;
      content: url('images/accordion_plus_icon.svg');
    }

    @include screen-md {
      display: none;
    }
  }

  .site-footer__search-category-inner,
  .site-footer__subsidy-category-inner,
  .site-footer__useful-category-inner,
  .site-footer__service-category-inner,
  &__search-sub-category-container,
  &__column-sub-category-container,
  &__subsidy-sub-category-container,
  &__useful-sub-category-container,
  &__service-sub-category-container {
    display: none;

    @include screen-md {
      display: block;
    }
  }

  .site-footer__search-links-container.is-open,
  .site-footer__subsidy-links-container.is-open,
  .site-footer__useful-links-container.is-open,
  .site-footer__service-links-container.is-open,
  &__search-category-container.is-open,
  &__column-category-container.is-open,
  &__subsidy-category-container.is-open
  &__useful-category-container.is-open
  &__service-category-container.is-open {
    .site-footer__subsidy-category-title-wrap,
    .site-footer__useful-category-title-wrap,
    .site-footer__service-category-title-wrap,
    .site-footer__search-category-title-wrap,
    .site-footer__column-category-title-container {
      border-bottom: 2px dashed #f4f4f4;

      @include screen-md {
        border-bottom: none;
      }
    }

    .site-footer__subsidy-sub-category-open-trigger::after,
    .site-footer__search-sub-category-open-trigger::after,
    .site-footer__column-sub-category-open-trigger::after,
    .site-footer__useful-sub-category-open-trigger::after,
    .site-footer__service-sub-category-open-trigger::after {
      content: url('images/accordion_minus_icon.svg');
    }

    .site-footer__search-category-inner,
    .site-footer__subsidy-category-inner,
    .site-footer__useful-category-inner,
    .site-footer__service-category-inner,
    .site-footer__search-sub-category-container,
    .site-footer__column-sub-category-container,
    .site-footer__subsidy-sub-category-container,
    .site-footer__useful-sub-category-container,
    .site-footer__service-sub-category-container {
      display: block;
    }
  }

  .site-footer__search-links-container.is-open,
  .site-footer__subsidy-links-container.is-open,
  .site-footer__useful-links-container.is-open,
  .site-footer__service-links-container.is-open {
    display: block;
  }

  &__search-links-container,
  &__subsidy-links-container,
  &__useful-links-container,
  &__service-links-container {
    border-bottom: 2px solid #f4f4f4;

    @include screen-md {
      border-bottom: none;
    }
  }

  &__reform-service-category-container {
    border-bottom: 2px solid #f4f4f4;

    @include screen-md {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      border-bottom: none;
    }
  }

  &__reform-service-category-list {
    @include screen-md {
      display: flex;
      flex-wrap: wrap;
      gap: 1em;
    }
  }

  &__reform-service-category-item {
    height: 60px;
    border-bottom: 2px solid #f4f4f4;

    @include screen-md {
      height: auto;
      padding-right: 1em;
      border-right: 1px solid #a1a0a0;
      border-bottom: none;

      &:last-of-type {
        padding-right: 0;
        border-right: none;
      }
    }
  }

  &__reform-service-category-link {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 1.3rem calc(0.5rem + 60px) 1.3rem 1.2rem;
    font-size: 1.1rem;
    line-height: 1.2;
    text-decoration: underline;
    background:
      url("images/link_list_arrow_icon.svg") no-repeat right 25px
      center;

    @include screen-md {
      display: block;
      padding: 0;
      background: none;
      color: #9e9e9e;
      font-size: 1rem;
    }
  }

  &__column-category-inner {
    display: flex;
    flex-direction: column;

    @include screen-md {
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  &__column-category-container {
    border-bottom: 2px solid #f4f4f4;

    @include screen-md {
      width: calc(100% / 4 - 10px);
      display: block;
      margin-bottom: 50px;
      border-bottom: none;
    }

    &:nth-child(1) {
      order: 2;
      .site-footer__column-category-title--name {
        background: url('images/icon_kitchen.svg') no-repeat 18px center;
      }
    }
    &:nth-child(2) {
      order: 1;
      .site-footer__column-category-title--name {
        background: url('images/icon_bath.svg') no-repeat 18px center;
      }
    }
    &:nth-child(3) {
      order: 4;
      .site-footer__column-category-title--name {
        background: url('images/icon_toilet.svg') no-repeat 23px center;
      }
    }
    &:nth-child(4) {
      order: 3;
      .site-footer__column-category-title--name {
        background: url('images/icon_lavatory.svg') no-repeat 21px center;
      }
    }
  }

  &__column-other-category-container {
    @include screen-md {
      width: 31%;
      display: block;
      margin-bottom: 50px;
      border-bottom: none;
    }

    .site-footer__column-category-container {
      @include screen-md {
        width: auto;
        margin-bottom: 15px;
        border-bottom: none;
      }
    }
  }

  &__column-category-title-container {
    display: flex;

    @include screen-md {
      margin: 0 0 12px 0;
    }
  }

  &__column-category-title--link {
    display: none;
    padding: 1.3rem 0.5rem 1.3rem 1.2rem;

    @include screen-md {
      display: inline-block;
      padding: 0;
      background: #F3F7F8;
      width: 100%;
      line-height: 30px;
      text-align: center;
      color: #7b7b7b;
      text-decoration: underline;
      font-size: 12px;
    }

    a {
      color: #7b7b7b;
    }
  }

  &__column-category-title--name {
    display: inline-block;
    padding: 1.3rem 1.2rem 1.3rem 3.8rem;

    @include screen-md {
      display: none;
    }
  }

  &__column-category-title--directLink {
    width: 100%;
    padding: 1.3rem calc(0.5rem + 60px) 1.3rem 1.2rem;
    text-decoration: underline;
    background:
      url("images/link_list_arrow_icon.svg") no-repeat right 25px
      center;

    @include screen-md {
      display: inline-block;
      padding: 0 0 0 12px;
      background:
        url("images/link_list_arrow_icon.svg") no-repeat left center /
        0.4rem;
    }
  }

  &__column-sub-category-list {
    @include screen-md {
      padding: 0 15px;
    }
  }

  &__column-sub-category-item {
    height: 60px;
    border-bottom: 2px dashed #f4f4f4;
    margin: 0 18px;
    text-decoration: none;

    &:last-of-type {
      border-bottom: none;
    }

    @include screen-md {
      height: auto;
      display: block;
      margin: 0 0 10px 0;
      border-bottom: none;
      text-decoration: underline;
    }
  }

  &__column-sub-category-link {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 calc(0.5rem + 60px) 0 2.2rem;
    color: #3c3c3c;
    font-size: 0.9rem;
    line-height: 1.2;
    text-decoration: none;
    background:
      url("images/link_list_arrow_icon.svg") no-repeat right 25px center;

    @include screen-md {
      display: inline-block;
      padding: 0;
      background: none;
      font-size: 1rem;
      color: #9b9b9b;
      text-decoration: underline;
    }

    &--top {
      @include screen-md {
        display: none;
      }  
    }
  }

  &__service {
    padding: 0 0 40px;
    background-color: #fafafa;

    @include screen-md {
      padding: 0 2rem 55px;
    }
  }

  &__service-inner {
    width: 100%;

    @include screen-md {
      max-width: 1000px;
      margin: auto;
    }
  }

  &__service-links-navigation {
    display: block;

    @include screen-md {
      padding-top: 20px;
      margin-bottom: 40px;
    }
  }

  &__about-category-title-container,
  &__search-category-title-container,
  &__subsidy-category-title-container,
  &__useful-category-title-container,
  &__service-category-title-container {
    @include screen-md {
      display: flex;
      border-top: none;
      border-bottom: none;
    }
  }

  &__service-category-title {
    width: calc(100% - 60px);
    padding: 1.3rem 0.5rem 1.3rem 1.9rem;
    color: #a1a0a0;
    font-size: 1.2rem;
    position: relative;

    &::before {
      content: "";
      width: 7px;
      height: calc(100% - 2.6rem);
      background-color: #a1a0a0;
      position: absolute;
      top: 50%;
      left: 0.8rem;
      transform: translateY(-50%);
    }

    @include screen-md {
      width: auto;
      margin: 2.5rem 0 1.5rem;
      padding: 0 0 0 0.5rem;
      border-left: 8px solid #a1a0a0;
      font-size: 1rem;

      &::before {
        display: none;
      }
    }
  }

  &__search-sub-category-list {
    display: flex;
    flex-direction: column;
  }

  &__about-sub-category-list,
  &__search-sub-category-list,
  &__subsidy-sub-category-list,
  &__useful-sub-category-list,
  &__service-sub-category-list {
    @include screen-md {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 1em;
    }
  }

  &__about-sub-category-item {
    height: 60px;
    border-bottom: 2px solid #f4f4f4;

    @include screen-md {
      height: auto;
      padding-right: 1em;
      border-right: 1px solid #a1a0a0;
      border-bottom: none;

      &:last-of-type {
        padding-right: 0;
        border-right: none;
      }
    }
  }

  &__search-sub-category-item,
  &__subsidy-sub-category-item,
  &__useful-sub-category-item,
  &__service-sub-category-item {
    height: 60px;
    border-bottom: 2px dashed #f4f4f4;
    margin: 0 18px;

    &:last-of-type {
      border-bottom: none;
    }

    @include screen-md {
      height: auto;
      margin: 0;
      padding-right: 1em;
      border-right: 1px solid #a1a0a0;
      border-bottom: none;

      &:last-of-type {
        padding-right: 0;
        border-right: none;
      }
    }
  }

  &__search-sub-category-item {
    &:nth-child(1) {
      order: 2;
    }
    &:nth-child2() {
      order: 1;
    }
    &:nth-child(3) {
      order: 4;
      @include screen-md {
        padding-right: 0;
        border-right: none;
      }
    }
    &:nth-child(4) {
      order: 3;
      @include screen-md {
        padding-right: 1em;
        border-right: 1px solid #a1a0a0;
      }
    }
  }

  &__about-sub-category-link {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 calc(0.5rem + 60px) 0 1.3rem;
    color: #3c3c3c;
    font-size: 1.1rem;
    line-height: 1.2;
    font-weight: bold;
    background:
      url("images/link_list_arrow_icon.svg") no-repeat right 25px
      center;

    @include screen-md {
      display: block;
      padding: 0;
      background: none;
      font-size: 1rem;
      color: #7B7B7B;
      font-weight: normal;
      text-decoration: underline;
    }
  }

  &__search-sub-category-link,
  &__subsidy-sub-category-link,
  &__useful-sub-category-link,
  &__service-sub-category-link {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 calc(0.5rem + 60px) 0 2.2rem;
    color: #3c3c3c;
    font-size: 0.9rem;
    line-height: 1.2;
    text-decoration: none;
    background:
      url("images/link_list_arrow_icon.svg") no-repeat right 25px center;

    @include screen-md {
      display: block;
      padding: 0;
      background: none;
      font-size: 1rem;
      color: #7B7B7B;
      text-decoration: underline;
    }
  }

  &__terms-and-company {
    @include screen-md {
      background-color: #fafafa;
    }
  }

  &__company {
    width: 100%;
    background-color: #fafafa;
    padding-top: 31px;
    padding-bottom: 27px;

    @include screen-md {
      padding-top: 36px;
      padding-bottom: 39px;
    }
  }

  &__company-inner {
    @include screen-md {
      width: 1000px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__company-info {
    display: block;
    margin: 0 auto 34px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d8d8d8;

    @include screen-md {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      padding: 0;
      border-bottom: none;
    }
  }

  &__company-info-logo {
    width: 68.02721088%;
    max-width: 200px;
    margin: 0 auto 36px;
    font-size: 0;

    @include screen-md {
      width: 186px;
      max-width: none;
      margin: 0 42px 0 0;
    }
  }

  &__company-info-logo-link {
    display: block;
  }

  &__company-info-summary {
    padding: 0 2.2rem;

    @include screen-md {
      padding: 0;
    }
  }

  &__company-info-name {
    margin-bottom: 1em;
    color: #3c3c3c;

    @include screen-md {
      margin-bottom: 10px;
    }
  }

  &__company-info-link {
    color: #3c3c3c;
    text-decoration: underline;
  }

  &__company-info-address {
    color: #9b9b9b;
    font-size: 0.85rem;
    line-height: 1.5;

    .sp-view {
      display: block;

      @include screen-md {
        display: none;
      }
    }
  }

  &__company-public-info {
    width: 78.4%;
    display: flex;
    margin: 0 auto;

    @include screen-md {
      width: 280px;
      margin: 0;
    }
  }

  &__company-public-image {
    width: 47px;
    min-width: 47px;
    margin-right: 26px;
    font-size: 0;

    @include screen-md {
      width: 52px;
      min-width: 52px;
    }
  }

  &__company-public-text {
    color: #9b9b9b;
    font-size: 0.85rem;
    line-height: 1.3333333333;

    @include screen-md {
      line-height: 1.5;
      font-weight: bold;
    }
  }

  &__terms-and-copyright {
    padding: 1rem 0.5rem 0;
    background: #FFFFFF;
    color: #9b9b9b;

    @include screen-md {
      background-color: #fafafa;
      padding: 1rem 0.5rem;
    }
  }

  &__terms-and-copyright-inner {
    width: 100%;

    @include screen-md {
      max-width: 1000px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto;
    }
  }

  &__terms {
    margin-bottom: 0.5em;

    @include screen-md {
      margin-bottom: 0;
    }
  }

  &__terms-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;

    @include screen-md {
      justify-content: flex-start;
    }
  }

  &__terms-item {
    display: block;
    padding-right: 1em;
    margin-right: 1em;
    margin-bottom: 1em;
    position: relative;

    @include screen-md {
      padding-right: 1.5em;
      margin-right: 1.5em;
      margin-bottom: 0;
    }

    &::before {
      content: "";
      width: 1px;
      height: 0.85rem;
      background-color: #9b9b9b;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    &:last-of-type {
      padding-right: 0;
      margin-right: 0;

      &::before {
        display: none;
      }
    }
  }

  &__terms-link {
    display: block;
    color: #9b9b9b;
    font-size: 0.95rem;
  }

  &__copyright {
    text-align: center;
    font-size: 0.8rem;
    color: #9b9b9b;
    line-height: 3;
    border-top: 1px solid #fafafa;

    @include screen-md {
      border-top: 0;
    }
  }
}
